header {
  /* overflow: hidden; */
  position: fixed;
  top: 0;
  height: 80px;
  display: flex;
  align-items: stretch;
  background-color: #000;
  color: #fff;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  z-index: 100;
  width: 100%;
  /* max-width: 100vw; */
  /* -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear; */
}

.hidden {
  display: none;
}

.headerTransition {
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
.navTransparent {
  background-color: transparent;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
}

.brand {
  width: 176px;
  font-size: 1.75rem;
  display: flex;
  justify-content: flex-start;
}

.brand:hover {
  cursor: pointer;
}

.navLogo {
  /* backup color */
  color: #61dafb;
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* text gradient */
  background: -webkit-linear-gradient(45deg, #61dafb, #4c6ef5);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 0.25rem;
}

.navMenu ul {
  display: flex;
  justify-content: center;
}

.navMenu li {
  display: inline;
  margin-right: 2rem;
}

.navMenu li:last-child {
  margin-right: 0;
}

.navMenu {
  width: calc(100% - 352px);
}

.navButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 176px;
}

.navMenu ul:hover > li:not(:hover) {
  color: rgba(255, 255, 255, 0.7);
}

.navMenu ul:hover > li:hover {
  color: rgba(255, 255, 255, 1);
}

.active {
  border-bottom: 4px solid #61dafb;
  padding-bottom: 1.5px;
  color: rgba(255, 255, 255, 1);
}

.sidebarContainer {
  display: none;
  position: absolute;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 0;
  top: 18.5px;
  left: 40px;
  width: calc(100vw - 80px);
  height: calc(100vh - 37px);
  font-size: 1.5rem;
}

.hamburgerContainer {
  display: none;
}

.hamburger {
  font-size: 2rem;
}

a:link.logoLinkContainer,
a:visited.logoLinkContainer,
a:hover.logoLinkContainer,
a:active.logoLinkContainer {
  color: #fff;
  font-size: 1.75rem;
  display: inherit;
  justify-content: inherit;
}

@media only screen and (max-width: 860px) {
  .navMenu li {
    margin-right: 1.5rem;
  }

  .navMenu li:last-child {
    margin-right: 0;
  }

  .navMenu ul {
    justify-content: flex-end;
  }
  .hidden {
    display: none;
  }
}

/* mobile */

@media only screen and (max-width: 768px) {
  .navMenu,
  .navButtonContainer {
    display: none;
  }
  .hamburgerContainer {
    display: block;
  }

  .sidebarContainer {
    display: flex;
    justify-content: space-between;

    padding-top: 18.5px;
  }

  .sidebarMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    border: 1px #ffffff solid;
    background-color: black;
    border-radius: 10px;
    width: 80vw;
    font-size: 1.5rem;
  }

  div.closeIconContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 0.5rem;
    padding-right: 1rem;
  }

  div.sidebarItemsContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
  }

  div.sidebarItemsContainer ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  div.sidebarItemsContainer li {
    padding-bottom: 1.5rem;
  }

  .sidebarContainer .toggleIcon {
    border: 1px #ffffff solid;
    border-radius: 8px;
    padding: 3px 6px;
    font-size: 2.5rem;
  }
  .sidebarContainer .toggleIcon:hover,
  .sidebarContainer .toggleIcon:active {
    box-shadow: 0px 0px 20px rgba(97, 218, 251, 0.8);
    background-color: rgba(97, 218, 251, 0.3);
  }

  .sidebarMenu .closeIcon {
    font-size: 2rem;
  }

  .sidebarMenu .closeIcon:hover {
    box-shadow: 0px 0px 20px rgba(97, 218, 251, 0.8);
    background-color: rgba(97, 218, 251, 0.3);
  }

  .sidebarMenu li:hover {
    color: rgba(97, 218, 251, 1);
  }
  .hidden {
    display: none;
  }
}

@media only screen and (max-width: 450px), only screen and (max-height: 450px) {
  .sidebarContainer {
    justify-content: flex-end;
    align-items: flex-start;
    align-items: center;
    padding-top: 0;
    top: 18.5px;
    left: 40px;
    width: calc(100vw - 80px);
    height: calc(100vh - 37px);
    font-size: 1.5rem;
  }

  .sidebarMenu {
    width: 100%;
    height: 100%;
  }
  .hidden {
    display: none;
  }
}

@media only screen and (max-width: 326px) {
  header {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .brand {
    font-size: 1.5rem;
  }

  .navLogo {
    font-size: 1.5rem;
  }
  .sidebarContainer {
    width: calc(100vw - 28px);
    left: 24px;
  }
  .hidden {
    display: none;
  }
}

@media only screen and (max-height: 350px) {
  div.sidebarItemsContainer li {
    padding-bottom: 1.25rem;
  }

  div.sidebarItemsContainer {
    padding-top: 0rem;
    padding-bottom: 1.5rem;
    margin-top: -1.75rem;
  }
  .hidden {
    display: none;
  }
}
