.positionsGrid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(180px, 1fr));
  gap: 40px;
  margin-bottom: 4rem;
}

.positionCard {
  border: 1px solid #ced4da;
  background-color: #f7f7f9;
  border-radius: 8px;
  min-width: 286px;
  min-height: 180px;
  box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 20%);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  padding: 1rem;
}

.gridItemsLeft {
  grid-column: 1 / 4;
  grid-row: 1 / 7;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gridItemsRight {
  grid-column: 4 / 7;
  grid-row: 1 / 7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

svg {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
}

.positive {
  color: #81df70;
}

.negative {
  color: #dc3545;
}

.negative::before {
  content: '-';
}
