.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.inputContainer input {
  margin-bottom: 8px;
}

.inputContainer p {
  color: #dc3545;
  height: 1rem;
  margin-bottom: 8px;
}

form input.inputError {
  border: 1px solid #dc3545;
}
