/* global css */
html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  box-sizing: border-box;
  min-width: 300px;
  min-height: 440px;
}

div,
nav,
header,
footer,
body {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: bold;
}

h1 {
  font-size: 2.5rem;
  letter-spacing: 3px;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.33rem;
}

h5 {
  font-size: 0.83rem;
}

h6 {
  font-size: 0.67rem;
}

.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

/* Forms */

.formScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  min-height: calc(100vh - 172px);
  position: relative;
}

@media only screen and (max-width: 396px) {
  .formScreen {
    min-height: calc(100vh - 204px);
  }
}

@media only screen and (max-width: 376px) {
  .formScreen {
    min-height: calc(100vh - 90px);
  }
}

@media only screen and (max-height: 512px) {
  .formScreen {
    min-height: calc(100vh + 120px);
  }
}

.formContainer {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 50%;
  padding: 0 1rem;
}

.formContainer h1 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.formContainer form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

form input,
form select {
  height: 3rem;
  background-color: #f7f7f9;
  border: 0 solid #ced4da;
  padding: 0 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

form label {
  padding-bottom: 0.5rem;
}

.formContainer span {
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.formContainer a:link {
  color: #61dafb;
  text-decoration: none;
}

/* visited link */
.formContainer a:visited {
  color: #61dafb;
}

/* mouse over link */
.formContainer a:hover {
  color: #4c6ef5;
}

/* selected link */
.formContainer a:active {
  color: #4c6ef5;
}

@media only screen and (max-width: 768px) {
  .formContainer {
    flex-basis: 100%;
    display: flex;
    max-width: 100%;
    padding: 0 2.5rem;
  }
}

/* Links */

a:link.linkContainer,
a:visited.linkContainer,
a:hover.linkContainer,
a:active.linkContainer {
  text-decoration: none;
}

/* Tables */

.tableScreen {
  display: flex;
  padding-top: 80px;
  min-height: calc(100vh - 172px);
  position: relative;
}

.tableContainer {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  padding: 1rem 1rem;
}

.tableContainer h1 {
  padding-bottom: 1rem;
}

table,
thead,
tbody,
tfoot,
tr,
th,
td {
  box-sizing: border-box;
  padding: 1rem;
}

table {
  width: 100%;
  text-align: left;
}

.portfolioTable th,
.portfolioTable td {
  width: 20%;
}

.portfolioTable th:nth-child(2),
.portfolioTable td:nth-child(2) {
  width: 40%;
}

tbody tr:nth-of-type(odd) {
  background-color: #f7f7f9;
}

thead tr {
  font-weight: bold;
  border-top: 1px solid #ced4da;
  border-bottom: 2px solid #ced4da;
}

tbody tr {
  border-bottom: 1px solid #ced4da;
}

.portfolioTable tr:last-child {
  font-weight: bold;
}
